.carousel .slide {
  background-color: white;
}

.gradient-banner {
  width: 100%;
  padding: 100px 0 150px;
  position: relative;
  overflow: hidden;
}

.gradient-banner::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background-image: linear-gradient(
    45deg,
    #009ec5 0%,
    #2e7eed 20%,
    #02225b 50%
  );
}

.grdient-banner_container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding-left: 10%;
  padding-right: 10%;
  gap: 2rem;
}

.gbc_left-part {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.main-head {
  display: block;
  margin: 2rem 0rem 0.5rem 0rem;
  font-size: 8rem;
  color: white;
}

.main-headline {
  display: block;
  margin: 2rem 0rem 0.5rem 0rem;
  font-size: 5rem;
  color: white;
}

.sub-headline {
  margin: 1rem 0;
  display: block;
  font-size: 1rem;
  color: white;
}

.download-btn {
  width: fit-content;
  padding: 1rem 3rem;
  background-color: #5691e4;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none;
  color: white;
  border-radius: 0.2rem;
}

.gbc_right-part {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.rounded-block {
  display: grid;
  grid-template-areas: "a b c";
  width: 70%;
  margin: auto;
  transform: translateY(-70px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 0.25rem;
}

.feature-box {
  border-radius: 0.5rem;
  height: 5rem;
  background-color: rgba(241, 241, 241, 0.462);
  display: grid;
  grid-template-areas: "feature-img" "feature-heading" "feature-subheading";
  justify-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 200px;
  box-sizing: content-box;
  padding-left: 1rem;
  animation-name: bottom-in;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  padding-right: 1rem;
}

.a {
  grid-area: a;
}

.b {
  grid-area: b;
}

.c {
  grid-area: c;
}

.feature-img {
  height: 5rem;
  grid-area: feature-img;
}

.feature-heading {
  text-align: center;
  height: fit-content;
  text-transform: uppercase;
  font-size: large;
  color: rgb(50, 143, 225);
  grid-area: feature-heading;
}

.feature-subheading {
  text-align: center;
  font-size: 0.9rem;
  grid-area: feature-subheading;
}

.business-section {
  margin: auto;
  padding-top: 2rem;
  margin-top: 3.25rem;
  margin-bottom: 5.5rem;
  display: grid;
  height: max-content;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  gap: 0rem;
}

.business-section-img {
  justify-self: center;
  align-self: right;
  width: 40%;
}

.business-section-info {
  justify-self: center;
  width: 80%;
  display: block;
  margin: auto;
  padding-right: 30%;
  height: fit-content;
}

.business-heading {
  text-transform: uppercase;
  text-align: center;
  display: block;
}

.business-subheading {
  text-align: center;
  font-size: 1.1rem;
  margin: 2rem;
  text-transform: uppercase;
  display: block;
}

.business-desc {
  justify-self: center;
  line-height: 1.4;
  font-size: 1rem;
  display: block;
  text-align: center;
  height: 10rem;
}

.information-section {
  margin: auto;
  padding-bottom: 2rem;
  margin-top: 3.25rem;
  margin-bottom: 5.5rem;
  display: grid;
  height: max-content;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  gap: 0rem;
}

.information-section-img {
  justify-self: center;
  align-self: right;
  width: 40%;
}

.information-section-info {
  justify-self: center;
  display: block;
  margin: auto;
  padding-left: 30%;
  height: fit-content;
}

.information-heading {
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.information-subheading {
  text-align: center;
  font-size: 1.1rem;
  margin: 2rem;
  text-transform: uppercase;
  display: block;
}

.information-desc {
  justify-self: center;
  line-height: 1.4;
  font-size: 1rem;
  display: block;
  text-align: center;
  height: 10rem;
}

.interface-section {
  display: block;
}

.interface-heading {
  font-weight: 300;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  line-height: 50px;
  text-transform: capitalize;
  display: block;
}

.interface-subheading {
  display: block;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  color: #808080;
  margin-top: 0.2rem;
}

.footer {
  margin-top: 5rem;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(241, 241, 241, 0.462);
}

.contact-card {
  justify-content: center;
  text-align: center;
  border-radius: 0.5rem;
  height: fit-content;
  display: grid;
  justify-items: center;
  box-sizing: content-box;
  animation-name: bottom-in;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
}

.footer-heading {
  display: block;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: black;
}

.footer-name {
  font-size: 1.2rem;
}

.footer-mail {
  font-size: 1.2rem;
}

.contacts {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.footer-icon {
  margin: 0 1rem;
  width: 25%;
}

.copyright {
  padding: 1% 0;
  color: white;
  display: block;
  text-align: center;
  margin-top: 2%;
  background-color: #5691e4;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.copyright > span {
  font-size: 0.6rem;
}

.copyright > a > span {
  font-size: 0.6rem;
}

@media only screen and (max-width: 800px) {
  .grdient-banner_container {
    padding: 0;
  }

  .main-head {
    display: block;
    margin: 2rem 0rem 0.5rem 0rem;
    font-size: 3rem;
    color: white;
  }
  .main-headline {
    display: block;
    margin: 2rem 0rem 0.5rem 0rem;
    font-size: 2rem;
    color: white;
  }

  .business-section {
    display: grid;
    grid-template-columns: 1fr;
    height: fit-content;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 0;
  }
  .business-section-info {
    padding-right: 0;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .business-section-img {
    padding-left: 0;
    width: 70%;
  }
  .information-section {
    display: grid;
    grid-template-columns: 1fr;
    height: fit-content;
    grid-template-rows: 1fr 1fr;
    width: 90%;
    padding-bottom: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    gap: 0rem;
  }

  .information-section-img {
    justify-self: center;
    align-self: right;
    grid-row: 1/2;
    width: 70%;
  }

  .information-section-info {
    padding-right: 0;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-left: 0%;
    grid-row: 2/3;
  }
  .rounded-block {
    grid-template-areas: "a" "b" "c";
  }
  .a {
    grid-area: a;
  }
  .b {
    grid-area: b;
  }
  .c {
    grid-area: c;
  }
  .header ul {
    background-color: #fff;
  }
  .header li a {
    padding: 15px;
    border-bottom: 1px dotted #ddd;
  }
  .gbc_left-part {
    grid-row: 2;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .gbc_right-part {
    grid-row: 1;
  }
}
