@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/*
primary #1a73e9

accent #03dac5

white-grey #e0e1e5

text color #222
*/
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
}

/* background color of pics */

#opt_img1 {
  background-color: crimson;
}

#opt_img2 {
  background-color: rgb(155, 46, 68);
}

#opt_img3 {
  background-color: rgb(228, 112, 135);
}

#opt_img4 {
  background-color: rgb(156, 12, 41);
}

#colc_img1 {
  background-color: rgb(243, 201, 96);
}

#colc_img2 {
  background-color: rgb(228, 192, 101);
}

#colc_img3 {
  background-color: rgb(216, 188, 119);
}

#colc_img4 {
  background-color: rgb(235, 171, 10);
}

/*  paragraph spacing for new line span is used  */

span {
  display: inline;
}

span:before {
  content: "\a";
  white-space: pre;
}

.privacy-maincontainer {
  margin: 2%;
}

/* media query */

@media (max-width: 1550px) {
  .privacy-head {
    margin-bottom: 4%;
    text-align: center;
    font-size: 320%;
    font-weight: 300;
  }
  .privacy-prgh {
    font-size: 120%;
    font-weight: 200;
    margin-bottom: 1%;
  }
  .privacy-prgh-t {
    font-size: 140%;
    font-weight: 400;
  }
}

@media (max-width: 910px) {
  .privacy-head {
    font-size: 320%;
    font-weight: 300;
  }
  .privacy-prgh {
    font-size: 120%;
    font-weight: 100;
  }
  .privacy-prgh-t {
    font-size: 2em;
    font-weight: 200;
  }
}

@media (max-width: 510px) {
  .privacy-head {
    font-size: 240%;
    font-weight: 200;
  }
  .privacy-prgh {
    font-size: 80%;
    font-weight: 100;
  }
  .privacy-prgh-t {
    font-size: 96%;
    font-weight: 400;
  }
}

span::before {
  white-space: nowrap;
}

span::before {
  white-space: nowrap;
}

* {
  margin: 0%;
  padding: 0%;
}

.container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-areas: "header smenu";
}

.shop-header {
  grid-area: header;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: 2px 2px 3em #00000056;
}

.shop-header::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.shop-image {
  width: 100%;
  height: 40%;
  object-fit: cover;
}

.shop-name {
  padding: 1em 1em 1em 1em;
}

.shop-description {
  padding: 0 1em 1em 1em;
}

.shop-sub-description {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 1em 1em 1em;
}

.shop-icon {
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.billonair-tag {
  text-align: center;
  color: darkgray;
  margin-top: 1em;
  padding-bottom: 1em;
}

.shop-menu {
  grid-area: smenu;
  overflow-y: auto;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 1em;
}

/* inner class of rmwc library */
.mdc-list-item__secondary-text {
  padding-bottom: 1rem;
}

@media (max-width: 750px) {
  .container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .shop-menu {
    padding: 0%;
    padding-top: 1em;
  }
}

.carousel .slide {
  background-color: white;
}

.gradient-banner {
  width: 100%;
  padding: 100px 0 150px;
  position: relative;
  overflow: hidden;
}

.gradient-banner::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background-image: linear-gradient(
    45deg,
    #009ec5 0%,
    #2e7eed 20%,
    #02225b 50%
  );
}

.grdient-banner_container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding-left: 10%;
  padding-right: 10%;
  grid-gap: 2rem;
  gap: 2rem;
}

.gbc_left-part {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.main-head {
  display: block;
  margin: 2rem 0rem 0.5rem 0rem;
  font-size: 8rem;
  color: white;
}

.main-headline {
  display: block;
  margin: 2rem 0rem 0.5rem 0rem;
  font-size: 5rem;
  color: white;
}

.sub-headline {
  margin: 1rem 0;
  display: block;
  font-size: 1rem;
  color: white;
}

.download-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 3rem;
  background-color: #5691e4;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none;
  color: white;
  border-radius: 0.2rem;
}

.gbc_right-part {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.rounded-block {
  display: grid;
  grid-template-areas: "a b c";
  width: 70%;
  margin: auto;
  transform: translateY(-70px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 0.25rem;
}

.feature-box {
  border-radius: 0.5rem;
  height: 5rem;
  background-color: rgba(241, 241, 241, 0.462);
  display: grid;
  grid-template-areas: "feature-img" "feature-heading" "feature-subheading";
  justify-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 200px;
  box-sizing: content-box;
  padding-left: 1rem;
  -webkit-animation-name: bottom-in;
          animation-name: bottom-in;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  padding-right: 1rem;
}

.a {
  grid-area: a;
}

.b {
  grid-area: b;
}

.c {
  grid-area: c;
}

.feature-img {
  height: 5rem;
  grid-area: feature-img;
}

.feature-heading {
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-transform: uppercase;
  font-size: large;
  color: rgb(50, 143, 225);
  grid-area: feature-heading;
}

.feature-subheading {
  text-align: center;
  font-size: 0.9rem;
  grid-area: feature-subheading;
}

.business-section {
  margin: auto;
  padding-top: 2rem;
  margin-top: 3.25rem;
  margin-bottom: 5.5rem;
  display: grid;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  grid-gap: 0rem;
  gap: 0rem;
}

.business-section-img {
  justify-self: center;
  align-self: right;
  width: 40%;
}

.business-section-info {
  justify-self: center;
  width: 80%;
  display: block;
  margin: auto;
  padding-right: 30%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.business-heading {
  text-transform: uppercase;
  text-align: center;
  display: block;
}

.business-subheading {
  text-align: center;
  font-size: 1.1rem;
  margin: 2rem;
  text-transform: uppercase;
  display: block;
}

.business-desc {
  justify-self: center;
  line-height: 1.4;
  font-size: 1rem;
  display: block;
  text-align: center;
  height: 10rem;
}

.information-section {
  margin: auto;
  padding-bottom: 2rem;
  margin-top: 3.25rem;
  margin-bottom: 5.5rem;
  display: grid;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  grid-gap: 0rem;
  gap: 0rem;
}

.information-section-img {
  justify-self: center;
  align-self: right;
  width: 40%;
}

.information-section-info {
  justify-self: center;
  display: block;
  margin: auto;
  padding-left: 30%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.information-heading {
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.information-subheading {
  text-align: center;
  font-size: 1.1rem;
  margin: 2rem;
  text-transform: uppercase;
  display: block;
}

.information-desc {
  justify-self: center;
  line-height: 1.4;
  font-size: 1rem;
  display: block;
  text-align: center;
  height: 10rem;
}

.interface-section {
  display: block;
}

.interface-heading {
  font-weight: 300;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  line-height: 50px;
  text-transform: capitalize;
  display: block;
}

.interface-subheading {
  display: block;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  color: #808080;
  margin-top: 0.2rem;
}

.footer {
  margin-top: 5rem;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(241, 241, 241, 0.462);
}

.contact-card {
  justify-content: center;
  text-align: center;
  border-radius: 0.5rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  justify-items: center;
  box-sizing: content-box;
  -webkit-animation-name: bottom-in;
          animation-name: bottom-in;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

.footer-heading {
  display: block;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: black;
}

.footer-name {
  font-size: 1.2rem;
}

.footer-mail {
  font-size: 1.2rem;
}

.contacts {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.footer-icon {
  margin: 0 1rem;
  width: 25%;
}

.copyright {
  padding: 1% 0;
  color: white;
  display: block;
  text-align: center;
  margin-top: 2%;
  background-color: #5691e4;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.copyright > span {
  font-size: 0.6rem;
}

.copyright > a > span {
  font-size: 0.6rem;
}

@media only screen and (max-width: 800px) {
  .grdient-banner_container {
    padding: 0;
  }

  .main-head {
    display: block;
    margin: 2rem 0rem 0.5rem 0rem;
    font-size: 3rem;
    color: white;
  }
  .main-headline {
    display: block;
    margin: 2rem 0rem 0.5rem 0rem;
    font-size: 2rem;
    color: white;
  }

  .business-section {
    display: grid;
    grid-template-columns: 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 0;
  }
  .business-section-info {
    padding-right: 0;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .business-section-img {
    padding-left: 0;
    width: 70%;
  }
  .information-section {
    display: grid;
    grid-template-columns: 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-template-rows: 1fr 1fr;
    width: 90%;
    padding-bottom: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    grid-gap: 0rem;
    gap: 0rem;
  }

  .information-section-img {
    justify-self: center;
    align-self: right;
    grid-row: 1/2;
    width: 70%;
  }

  .information-section-info {
    padding-right: 0;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-left: 0%;
    grid-row: 2/3;
  }
  .rounded-block {
    grid-template-areas: "a" "b" "c";
  }
  .a {
    grid-area: a;
  }
  .b {
    grid-area: b;
  }
  .c {
    grid-area: c;
  }
  .header ul {
    background-color: #fff;
  }
  .header li a {
    padding: 15px;
    border-bottom: 1px dotted #ddd;
  }
  .gbc_left-part {
    grid-row: 2;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .gbc_right-part {
    grid-row: 1;
  }
}

