/*
primary #1a73e9

accent #03dac5

white-grey #e0e1e5

text color #222
*/

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  user-select: none;
  width: 100%;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
}

/* background color of pics */

#opt_img1 {
  background-color: crimson;
}

#opt_img2 {
  background-color: rgb(155, 46, 68);
}

#opt_img3 {
  background-color: rgb(228, 112, 135);
}

#opt_img4 {
  background-color: rgb(156, 12, 41);
}

#colc_img1 {
  background-color: rgb(243, 201, 96);
}

#colc_img2 {
  background-color: rgb(228, 192, 101);
}

#colc_img3 {
  background-color: rgb(216, 188, 119);
}

#colc_img4 {
  background-color: rgb(235, 171, 10);
}

/*  paragraph spacing for new line span is used  */

span {
  display: inline;
}

span:before {
  content: "\a";
  white-space: pre;
}

.privacy-maincontainer {
  margin: 2%;
}

/* media query */

@media (max-width: 1550px) {
  .privacy-head {
    margin-bottom: 4%;
    text-align: center;
    font-size: 320%;
    font-weight: 300;
  }
  .privacy-prgh {
    font-size: 120%;
    font-weight: 200;
    margin-bottom: 1%;
  }
  .privacy-prgh-t {
    font-size: 140%;
    font-weight: 400;
  }
}

@media (max-width: 910px) {
  .privacy-head {
    font-size: 320%;
    font-weight: 300;
  }
  .privacy-prgh {
    font-size: 120%;
    font-weight: 100;
  }
  .privacy-prgh-t {
    font-size: 2em;
    font-weight: 200;
  }
}

@media (max-width: 510px) {
  .privacy-head {
    font-size: 240%;
    font-weight: 200;
  }
  .privacy-prgh {
    font-size: 80%;
    font-weight: 100;
  }
  .privacy-prgh-t {
    font-size: 96%;
    font-weight: 400;
  }
}
