* {
  margin: 0%;
  padding: 0%;
}

.container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-areas: "header smenu";
}

.shop-header {
  grid-area: header;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: 2px 2px 3em #00000056;
}

.shop-header::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.shop-image {
  width: 100%;
  height: 40%;
  object-fit: cover;
}

.shop-name {
  padding: 1em 1em 1em 1em;
}

.shop-description {
  padding: 0 1em 1em 1em;
}

.shop-sub-description {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 1em 1em 1em;
}

.shop-icon {
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.billonair-tag {
  text-align: center;
  color: darkgray;
  margin-top: 1em;
  padding-bottom: 1em;
}

.shop-menu {
  grid-area: smenu;
  overflow-y: auto;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 1em;
}

/* inner class of rmwc library */
.mdc-list-item__secondary-text {
  padding-bottom: 1rem;
}

@media (max-width: 750px) {
  .container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .shop-menu {
    padding: 0%;
    padding-top: 1em;
  }
}
